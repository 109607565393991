import "@lwc/synthetic-shadow";
import MyApp from "lwc/app";
import { createElement } from "lwc";

const app = createElement("lwc-app", { is: MyApp });

// eslint-disable-next-line @lwc/lwc/no-document-query
const mainPanel = document.querySelector("#main");
mainPanel.innerHTML = "";
mainPanel.appendChild(app);
console.log("LWC LOADER");
if (initialized) {
	initialized(app);
}
