import { LightningElement, api } from "lwc";

const pages = {
	LOGO: "LOGO",
	ADMIN: "ADMIN",
	SCANNER: "SCANNER",
	BOOKMARK: "BOOKMARK",
	ELECTRON: "ELECTRON",
};

export default class App extends LightningElement {
	_mode = "UNKNOWN";
	_secured = true;
	currentPage = pages.LOGO;

	@api
	get mode() {
		return this._mode;
	}
	set mode(value) {
		console.log(`MODE: ${value}`);
		this._mode = value;
	}

	@api
	get secured() {
		return this._secured;
	}
	set secured(value) {
		console.log(`SECURED: ${value}`);
		this._secured = value;
	}

	get isLogo() {
		return this.currentPage === pages.LOGO;
	}

	get isAdmin() {
		return this.currentPage === pages.ADMIN;
	}

	get isScanner() {
		return this.currentPage === pages.SCANNER;
	}

	get isBookmark() {
		return this.currentPage === pages.BOOKMARK;
	}

	get isElectron() {
		return this.currentPage === pages.ELECTRON;
	}

	constructor() {
		super();
		console.log("APP COMPONENT");
	}

	onLoggedIn() {
		this.currentPage = pages[this.mode];
	}

	onScanClick() {
		this.currentPage = pages.SCANNER;
		window.history.pushState(null, null, "/");
	}

	onBookmarkClick() {
		this.currentPage = pages.BOOKMARK;
		window.history.pushState(null, null, "/bookmark");
	}

	onAdminClick() {
		this.currentPage = pages.ADMIN;
		window.history.pushState(null, null, "/admin");
	}
}
