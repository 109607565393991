import { LightningElement, api } from "lwc";
import electronComputerTestHardware from "lwc/electronComputerTestHardware/electronComputerTestHardware.js";

export default class ElectronPageComputerSetup extends LightningElement {
	_lines = [];
	_progress = {};
	_buttons = [
		// neutral or brand
		{ label: "error", variant: "brand" },
		{ label: "warning", variant: "brand" },
		{ label: "success", variant: "brand" },
		{ label: "instruction", variant: "brand" },
		{ label: "message", variant: "brand" },
		{ label: "info", variant: "brand" },
		{ label: "debug", variant: "brand" },
	];
	_cards = {
		mode: "LIST",
		number: 0,
	};

	timer = null;
	isOnlyErrors = false;
	sortDirection = "asc";

	@api async checkHardware(value) {
		if (!value.skipHardwareTest) {
			this.refs.page.style.backgroundColor = "orange";
			await electronComputerTestHardware.open({
				size: "small",
				disableClose: true,
			});
		}
		setTimeout(() => {
			window.electronAPI.R2M2R_StartSoftwareTest();
			this.refs.page.style.color = "blue";
		}, 0);
	}

	@api
	get progress() {
		return this._progress;
	}
	set progress(value) {
		if (value) {
			console.log(value);
			if (this.refs?.page) {
				console.log("QUEUE PROGRESS - CLEARED");
				clearTimeout(this.timer);
				this.updateDisplay(value);
			} else {
				console.log("QUEUE PROGRESS - QUEUED");
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					console.log("QUEUE PROGRESS - EXECUTED");
					this.updateDisplay(value);
				}, 5e3);
			}
		}
	}

	get buttons() {
		this._buttons.forEach((button) => {
			button.variant = this.isOnlyErrors ? "neutral" : "brand";
			if (["error", "warning"].includes(button.label)) {
				button.variant = "brand";
			}
		});
		this.filterAndSort();
		return this._buttons;
	}

	get onlyErrorsLabel() {
		return !this.isOnlyErrors ? "Show Only Errors" : "Show All Messages";
	}

	get showCardsLabel() {
		return this._cards.mode === "CARD" ? "Show List" : "Show Cards";
	}

	get isCardMode() {
		return this._cards.mode === "CARD";
	}

	get card() {
		const card = { ...this.progress.lines[this._cards.number] };

		card.disablePrev = !(this._cards.number > 0);
		card.disableNext = !(this._cards.number < this.progress.lines.length - 1);
		return card;
	}

	get sortIcon() {
		if (this.sortDirection === "asc") {
			return "utility:arrowdown";
		} else {
			return "utility:arrowup";
		}
	}

	get machine() {
		let output = "Wait...";
		if (this.progress?.machine) {
			output = `${this.progress.machine.roomName} #${this.progress.machine.computerNumber}`;
		}
		return output;
	}

	onTypeButtonClicked(event) {
		let button = this._buttons.find((button) => button.label === event.target.label);
		button.variant = button.variant === "brand" ? "neutral" : "brand";
		this.filterAndSort();
	}

	onSortButtonClicked() {
		this._cards = { ...this._cards };
		this._cards.number = 0;
		this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
		this.filterAndSort();
	}

	onOnlyErrorsToggle() {
		this.isOnlyErrors = !this.isOnlyErrors;
	}

	onShowCardsToggle() {
		this._cards = { ...this._cards };
		this._cards.number = 0;
		this._cards.mode = this._cards.mode === "CARD" ? "LIST" : "CARD";
	}

	onFirstCardClick() {
		this._cards = { ...this._cards };
		this._cards.number = 0;
	}

	onPreviousCardClick() {
		this._cards = { ...this._cards };
		this._cards.number--;
	}

	onNextCardClick() {
		this._cards = { ...this._cards };
		this._cards.number++;
	}

	onLastCardClick() {
		this._cards = { ...this._cards };
		this._cards.number = this.progress.lines.length - 1;
	}

	updateDisplay(value) {
		// Save data locally
		this._progress = { ...value };
		this._lines = value.lines.map((line) => {
			line = { ...line };
			line.class = `card ${line.type}`;
			return line;
		});

		// Set color
		this.refs.page.style.color = "blue";
		if (this.progress.isDone) {
			if (this.progress.countErrors !== 0) {
				this.refs.page.style.backgroundColor = "red";
			} else if (this.progress.countWarnings !== 0) {
				this.refs.page.style.backgroundColor = "orange";
			} else {
				this.refs.page.style.backgroundColor = "lime";
			}
		} else {
			this.refs.page.style.backgroundColor = "unset";
		}

		// Fix and save original data
		this.filterAndSort();

		console.log(JSON.parse(JSON.stringify(this.progress)));
	}

	filterAndSort() {
		// Find labels to include
		const includedStates = [];
		this._buttons.forEach((button) => {
			if (button.variant === "brand") {
				includedStates.push(button.label);
			}
		});

		// filter the list
		let temp = this._lines.filter((line) => includedStates.includes(line.type));

		// Sort it
		let direction = this.sortDirection === "asc" ? 1 : -1;
		temp = temp.sort((a, b) => {
			return direction * (a < b ? -1 : 1);
		});

		// Save results
		this._buttons = [...this._buttons];
		this._progress.lines = temp;
	}
}
