import { LightningElement, api } from "lwc";
import appLogin from "lwc/appLogin/appLogin.js";
import { imperativeData } from "lwc/utilsData/utilsData.js";

export default class AppLogo extends LightningElement {
	@api secured;
	isLoading = true;
	isIntialized = false;

	connectedCallback() {
		if (!this.isIntialized) {
			setTimeout(async () => {
				if (this.secured) {
					try {
						// Try to use the secret in the cookie
						const secret = readCookie("secret");
						await imperativeData({ operation: "login", password: secret });
						this.isLoading = false;
						this.dispatchEvent(new CustomEvent("loggedin"));
					} catch (error) {
						this.isLoading = false;
						const result = await appLogin.open({
							size: "small",
							disableClose: true,
						});
						if (result.isLoggedIn) {
							this.dispatchEvent(new CustomEvent("loggedin"));
						} else {
							alert("Not logged in");
						}
					}
				} else {
					this.dispatchEvent(new CustomEvent("loggedin"));
				}
			}, 2e3);
		}
	}
}
