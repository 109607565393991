import { LightningElement, api } from "lwc";

export default class ElectronPageColor extends LightningElement {
	_color;

	@api
	get color() {
		return this._color;
	}
	set color(value) {
		this._color = value;
		if (value) {
			setTimeout(() => {
				this.refs.page.style.backgroundColor = value;
			}, 0);
		}
	}
}
