import Utils from "lwc/utils/utils.js";
import { LightningElement, wire } from "lwc";
import { wiredData } from "lwc/utilsData/utilsData.js";

export default class AdminHome extends LightningElement {
	computers = [];
	latestUpdate = {
		version: "0.0.0",
		dttm: new Date(0).toString(),
	};
	defaultTab = null;

	constructor() {
		super();
		this.defaultTab = readCookie("adminTab");
	}

	@wire(wiredData, { operation: "getLatestUpdate" })
	getLatestUpdate({ data, error }) {
		if (data) {
			this.latestUpdate = {
				version: data.version,
				dttm: new Date(data.stat.ctimeMs).toString(),
			};
		} else if (error) {
			this.isLoading = false;
			Utils.reportError({ error });
		}
	}

	onComputersSelected(event) {
		this.computers = event.detail;
	}

	onMonitorActiveTab() {
		this.defaultTab = "Monitor";
		writeCookie("adminTab", this.defaultTab);
	}

	onControlActiveTab() {
		this.defaultTab = "Control";
		writeCookie("adminTab", this.defaultTab);
	}

	onCountsActiveTab() {
		this.defaultTab = "Counts";
		writeCookie("adminTab", this.defaultTab);
	}

	onActionRequested(event) {
		if (this.refs.controller) {
			this.refs.controller.controlComputer(event.detail);
		}
	}
}
