import Utils from "lwc/utils/utils.js";
import { LightningElement, api, wire } from "lwc";
import { imperativeData, wiredData } from "lwc/utilsData/utilsData.js";

export default class AdminControl extends LightningElement {
	@api computers;

	webUrl = { url: "URL" };
	webUrls = [];
	webUrlsMap = null;
	batch = {};
	batches = [];
	batchesMap = null;
	color = "#0000FF";
	isLoading = true;
	isInitialized = true;

	//#region App Info
	get browseButtonDisabled() {
		return !this.webUrl.url.toUpperCase().startsWith("HTTP");
	}

	get executeBatchDisabled() {
		let disabled = true;
		if (this.batch?.script) {
			disabled = this.batch.script.length <= 1;
		}
		return disabled;
	}

	@wire(wiredData, { operation: "getWebURLs" })
	getWebURLs({ data, error }) {
		if (data) {
			this.webUrlsMap = new Map();
			data.records.forEach((record) => {
				this.webUrlsMap.set(record.Id, record);
			});
			this.webUrls = data.records;
			if (this.webUrlsMap && this.batchesMap) {
				this.isLoading = false;
			}
		} else if (error) {
			this.isLoading = false;
			Utils.reportError({ error });
		}
	}

	@wire(wiredData, { operation: "getBatches" })
	getBatches({ data, error }) {
		if (data) {
			this.batchesMap = new Map();
			data.records.forEach((record) => {
				this.batchesMap.set(record.Id, record);
				this.batches.push({ label: record.Name, value: record.Id });
			});
			this.batches = data.records;
			if (this.webUrlsMap && this.batchesMap) {
				this.isLoading = false;
			}
		} else if (error) {
			this.isLoading = false;
			Utils.reportError({ error });
		}
	}

	renderedCallback() {
		if (!this.isInitialized) {
			this.refs.colorThis.style.backgroundColor = this.color;
			this.isInitialized = true;
		}
	}

	onWebUrlChange(event) {
		const Id = event.detail.value;
		const record = this.webUrlsMap.get(Id);
		this.webUrl = {
			value: Id,
			url: record.URL__c,
		};
	}

	onBatchChange(event) {
		const Id = event.detail.value;
		const record = this.batchesMap.get(Id);
		this.batch = {
			value: Id,
			script: record.Batch__c,
		};
	}

	//#endregion

	//#region Remote Computer
	onColorInput(event) {
		const color = event.target.value;
		this.controlComputer({ option: "color", value: color });
	}

	onOpenTopWindowClick() {
		this.controlComputer({ option: "window", value: "openTop" });
	}

	onOpenNormalWindowClick() {
		this.controlComputer({ option: "window", value: "openNormal" });
	}

	onCloseWindowClick() {
		this.controlComputer({ option: "window", value: "close" });
	}

	onToggleDebugger() {
		this.controlComputer({ option: "window", value: "debugger" });
	}

	onComputerUpdate() {
		this.controlComputer({ option: "update", value: "start" });
	}

	onComputerTest() {
		this.controlComputer({ option: "test", value: "start" });
	}

	onComputerRestart() {
		this.controlComputer({ option: "restart", value: "computer" });
	}

	onApplicationRestart() {
		this.controlComputer({ option: "restart", value: "application" });
	}

	onDeleteRegistration() {
		this.controlComputer({ option: "restart", value: "registration" });
	}

	onBrowseClick() {
		this.controlComputer({ option: "browse", value: this.webUrl.url });
	}

	onExecuteBatchClick() {
		this.controlComputer({ option: "execute", value: this.batch.script });
	}

	@api
	async controlComputer({ option, value }) {
		try {
			this.isLoading = true;
			await imperativeData({
				operation: "adminControlComputer",
				computers: this.computers,
				option,
				value,
			});
			this.isLoading = false;
		} catch (error) {
			this.isLoading = false;
			Utils.reportError({ error });
		}
	}

	//#endregion
}
