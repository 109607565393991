import { LightningElement, api } from "lwc";
import utilsQrcodeScanner from "lwc/utilsQrcodeScanner/utilsQrcodeScanner.js";
export default class ScannerQRCode extends utilsQrcodeScanner {
	@api roomName = null;
	@api computerNumber = null;

	writeText() {
		let msgs;
		let position;
		let measurements;
		const ctx = this.canvas2D;
		const canvas = this.refs.canvas;

		const drawRect = (x, y, m) => {
			ctx.fillStyle = "black";
			ctx.strokeStyle = "black";
			// fillRect(x, y, width, height)
			ctx.fillRect(x + m.myBoxX, y + m.myBoxY, m.myBoxW, m.myBoxH);
		};

		const drawText = (text, color, x, y, m) => {
			ctx.fillStyle = color;
			ctx.strokeStyle = color;
			// fillText(text, x, y)
			ctx.fillText(text, x + m.myTextX, y + m.myTextY);
		};

		const drawCenters = (x, y) => {
			ctx.fillStyle = "blue";
			ctx.strokeStyle = "blue";
			// fillRect(x, y, width, height)
			ctx.fillRect(0, y, canvas.width, 1);
			ctx.fillRect(x, 0, 1, canvas.height);
		};

		const calculateMeasurement = (msg) => {
			const measurement = ctx.measureText(msg);
			if (measurement.fontBoundingBoxAscent) {
				Object.assign(measurement, {
					myHeight: measurement.fontBoundingBoxAscent + measurement.fontBoundingBoxDescent,
				});
			} else {
				Object.assign(measurement, {
					myHeight: measurement.actualBoundingBoxAscent + measurement.actualBoundingBoxDescent,
				});
			}
			Object.assign(measurement, {
				myTextX: -measurement.width / 2,
				myTextY: 0,
				myBoxX: -measurement.width / 2 - 10,
				myBoxY: -measurement.fontBoundingBoxAscent,
				myBoxW: measurement.width + 20,
				myBoxH: measurement.myHeight + 10,
			});
			return measurement;
		};

		msgs = {};
		ctx.font = "2rem serif";
		if (this.isProcessing) {
			msgs = {
				title: "Please Wait",
				subtitle: "Registering",
				color: "orange",
			};
		} else {
			msgs = {
				title: this.roomName ? this.roomName : "Computer Number",
				subtitle: this.computerNumber ? this.computerNumber : "Unknown",
				color: this.computerNumber ? "lime" : "red",
			};
		}
		measurements = {
			title: calculateMeasurement(msgs.title),
			subtitle: calculateMeasurement(msgs.subtitle),
		};

		position = { x: canvas.width / 2, y: canvas.height / 2 - measurements.title.myHeight };
		// drawCenters(position.x, position.y);
		drawRect(position.x, position.y, measurements.title);
		drawText(msgs.title, msgs.color, position.x, position.y, measurements.title);

		position = { x: canvas.width / 2, y: canvas.height / 2 + measurements.subtitle.myHeight };
		// drawCenters(position.x, position.y);
		drawRect(position.x, position.y, measurements.subtitle);
		drawText(msgs.subtitle, msgs.color, position.x, position.y, measurements.subtitle);
	}
}
