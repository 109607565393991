// import { api, track, wire } from "lwc";
// import Utils from "lwc/utils/utils.js";
import LightningModal from "lightning/modal";
// import { wiredData } from "lwc/utilsData/utilsData.js";

export default class ElectronComputerTestHardware extends LightningModal {
	hasMouse = false;
	hasKeyboard = false;
	message = "Click here!";

	onMouse() {
		this.hasMouse = true;
		this.message = "Press ESC";
	}

	onKey(event) {
		if (this.hasMouse) {
			const key = event.key.toUpperCase();
			console.log(key);
			if (key === "ESCAPE") {
				this.hasKeyboard = true;
				this.disableClose = false;
				this.close();
			} else {
				this.message = "Invalid key";
				setTimeout(() => {
					this.message = "Press ESC";
				}, 1e3);
			}
		}
	}
}
