import Utils from "lwc/utils/utils.js";
import { LightningElement, wire } from "lwc";
import { wiredData } from "lwc/utilsData/utilsData.js";
import utilsSelectLocation from "lwc/utilsSelectLocation/utilsSelectLocation.js";

export default class BookmarkHome extends LightningElement {
	room = {};
	event = {};
	qrUrl = "";
	secret = "";
	qrImage = "";
	promptedLocation = false;

	async renderedCallback() {
		if (!this.promptedLocation) {
			this.promptedLocation = true;
			this.event = getJsonFromCookie("event");
			this.room = getJsonFromCookie("room");
			const result = await utilsSelectLocation.open({
				size: "small",
				disableClose: true,
				config: {
					room: this.room,
					event: this.event,
				},
			});
			this.room = result.room;
			this.event = result.event;
			writeCookie("event", JSON.stringify(this.event));
			writeCookie("room", JSON.stringify(this.room));
		}
	}

	@wire(wiredData, { operation: "getQRCodeURL" })
	getQRCodeURL({ data, error }) {
		if (data) {
			this.qrUrl = data.url;
			this.secret = data.secret;
			QRCode.toDataURL(
				this.qrUrl,
				{
					scale: 50,
					margin: 0,
					color: {
						dark: "#000",
						light: "#0000", // Transparent background
					},
				},
				(error, image) => {
					if (error) {
						Utils.reportError({ error });
					} else {
						this.qrImage = image;
					}
				}
			);
		} else if (error) {
			Utils.reportError({ error });
		}
	}
}
