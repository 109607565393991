import { api } from "lwc";
import Utils from "lwc/utils/utils.js";
import LightningModal from "lightning/modal";
import { imperativeData } from "lwc/utilsData/utilsData.js";

export default class ScannerRegistering extends LightningModal {
	_computerData;
	response = {};
	isLoading = false;
	isSuccess = false;
	status = "Registering...";
	continueDisabled = true;

	@api
	get computerData() {
		return this._computerData;
	}
	set computerData(data) {
		this.assignComputerLocation(data);
	}

	async assignComputerLocation(data) {
		this.isLoading = true;
		this.isSuccess = false;
		if (data) {
			try {
				debugger;
				this.response = await imperativeData({ operation: "assignComputerLocation", ...data });
				this.status = "Please scan the next computer";
				this.isSuccess = true;
				this.isLoading = false;
				this.continueDisabled = false;
			} catch (error) {
				this.isSuccess = false;
				this.isLoading = false;
				this.continueDisabled = false;
				this.status = "Registration failed";
				Utils.reportError({ error });
			}
		}
	}

	onContinueClick() {
		this.disableClose = false;
		this.close({ isSuccess: this.isSuccess, data: this.response });
	}
}
