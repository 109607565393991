import { LightningElement, api } from "lwc";

export default class ElectronPageComputerSetup extends LightningElement {
	_progress = {};

	@api
	get progress() {
		return this._progress;
	}
	set progress(value) {
		if (value) {
			this.updateDisplay(value);
		}
	}

	updateDisplay(value) {
		this._progress = { ...value };
		this.progress.lines = value.lines.map((line) => {
			line = { ...line };
			line.class = `card ${line.cssClass}`;
			return line;
		});

		console.log(JSON.parse(JSON.stringify(this.progress)));
	}
}
