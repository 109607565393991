import { LightningElement } from "lwc";

export default class ElectronDisplayQRCode extends LightningElement {
	qrcode = {};
	letter = "";
	letterTimer = null;
	isInitialized = false;
	isManualScanOptionVisible = false;

	get manualScanDisabled() {
		return this.letter.length !== 1;
	}

	async connectedCallback() {
		if (!this.isInitialized) {
			this.isInitialized = true;
			window.electronAPI.M2R_QRCodeScanned(async (event, value) => {
				console.log(value);
				this.refs.page.style.backgroundColor = value.color;
				if (value.error) {
					if (typeof value.error !== "string") {
						value.error = JSON.stringify(value.error);
					}
					// The styles were not being applied!
					// requestAnimationFrame(loop.bind(this));
					setTimeout(() => {
						alert(value.error);
					}, 1e2);
				}
			});
			this.qrcode = await window.electronAPI.R2M2R_GetQRCode();
			this.isManualScanOptionVisible = this.qrcode.isManualScanOptionVisible;
			console.log(this.qrcode);
			console.log(`${this.qrcode.data.roomName} (${this.qrcode.data.computerNumber})`);
		}
	}

	renderedCallback() {
		this.refs.manualScan?.focus();
	}

	onKeydown(event) {
		const letter = event.key.toUpperCase();
		clearTimeout(this.letterTimer);
		if (letter.length === 1) {
			this.letterTimer = setTimeout(() => {
				this.letter = letter;
			}, 3e1);
		}
		if (letter === "ENTER") {
			this.onManualScan();
		}
	}

	async onManualScan() {
		await window.electronAPI.R2M2R_ManualScan({ letter: this.letter });
	}
}
