import { api, wire } from "lwc";
import Utils from "lwc/utils/utils.js";
import LightningModal from "lightning/modal";
import LightningPrompt from "lightning/prompt";
import { imperativeData, wiredData } from "lwc/utilsData/utilsData.js";

export default class UtilsSelectLocation extends LightningModal {
	_config = {};
	isLoading = true;
	scannerName = "";
	includeStorage = "UNKNOWN";
	refresh = new Date();

	rooms = null;
	room = {};
	events = null;
	event = {};
	scanners = null;
	scanner = {};

	@api
	get config() {
		return this._config;
	}
	set config(value) {
		if (value) {
			this._config = value;
			this.room = value.room;
			this.event = value.event;
			this.scanner = value.scanner;
			// the @wire was not firing when I was just doing true/false
			this.includeStorage = value.includeStorage ? "YES" : "NO";
		}
	}

	@wire(wiredData, { operation: "getScanners", refresh: "$refresh" })
	getScanners({ data, error }) {
		if (data) {
			this.scanners = data.records;
			if (data.records.length === 1) {
				this.scanner = {
					value: data.records[0].Id,
					label: data.records[0].Name,
					letter: data.records[0].Letter__c,
				};
			}
			this.isLoading = false;
		} else if (error) {
			this.isLoading = false;
			Utils.reportError({ error });
		}
	}

	@wire(wiredData, { operation: "getEvents", includeStorage: "$includeStorage" })
	getEvents({ data, error }) {
		if (data) {
			this.events = data.records;
			if (data.records.length === 1) {
				this.event = {
					value: data.records[0].Id,
					label: data.records[0].Name,
				};
			}
			this.isLoading = false;
		} else if (error) {
			this.isLoading = false;
			Utils.reportError({ error });
		}
	}

	@wire(wiredData, { operation: "getRooms", eventId: "$event.value" })
	getRooms({ data, error }) {
		if (data) {
			this.rooms = data.records;
			if (data.records.length === 1) {
				this.room = {
					value: data.records[0].Id,
					label: data.records[0].Name,
				};
			}

			let validRoom = this.rooms.filter((room) => room.Id === this.room.value);
			if (validRoom.length === 1) {
				// Nothing
			} else {
				// Clear
				this.room = {};
			}

			this.isLoading = false;
		} else if (error) {
			this.isLoading = false;
			Utils.reportError({ error });
		}
	}

	get continueDisabled() {
		let hasCompletedData = true;
		hasCompletedData &= !!this.event.value;
		hasCompletedData &= !!this.room.value;
		if (this.config.showScannerName) {
			hasCompletedData &= !!this.scanner.value;
		}
		return !hasCompletedData;
	}

	onEventChange(event) {
		if (this.event.value !== event.detail.value) {
			this.isLoading = true;
		}
		this.event = event.detail;
	}

	onRoomChange(event) {
		this.room = event.detail;
	}

	onScannerChange(event) {
		this.scanner = event.detail;
	}

	onContinueClick() {
		this.disableClose = false;
		this.close({ event: this.event, room: this.room, scanner: this.scanner });
	}

	onRegisterClick() {
		LightningPrompt.open({
			message: "What's your name",
			label: "Rgistation",
		}).then(async (name) => {
			if (name) {
				console.log(name);
				try {
					this.isLoading = true;
					let response = await imperativeData({
						operation: "registerScanner",
						name,
					});
					console.log(response);
					this.refresh = new Date();
					setTimeout(() => {
						this.scanner = {
							value: response.Id,
							label: response.Name,
						};
					}, 5e2);
				} catch (error) {
					Utils.reportError({ error });
				}
				this.isLoading = false;
			}
			debugger;
		});
	}
}
