import Toastify from "resources/toastify-es.js";

export default class Utils {
	static showNotification({ title = "", message = "", variant = "success", mode = "dismissible" }) {
		let toast;

		const makeToast = () => {
			const container = document.createElement("div"); //														<div class="slds-notify_container slds-is-relative">
			container.classList.add("slds-notify_container", "slds-is-relative");
			container.style.minWidth = "25vw";
			container.style.maxWidth = "90vw";

			const notify = document.createElement("div"); // 																	<div class="slds-notify slds-notify_toast slds-theme_error" role="status">
			notify.classList.add("slds-notify", "slds-notify_toast", options.theme);
			notify.style.minWidth = "25vw";
			container.appendChild(notify);

			const content = document.createElement("div"); // 																				<div class="slds-notify__content">
			content.classList.add("slds-notify__content");

			const cmpTitle = document.createElement("h2"); //																							<h2 class="slds-text-heading_small">TITLE</h2>
			cmpTitle.classList.add("slds-text-heading_small");
			cmpTitle.textContent = title;
			content.appendChild(cmpTitle);

			const subtitle = document.createTextNode(message); //																						Subtitle
			content.appendChild(subtitle);
			notify.appendChild(content); // 																								</div>

			if (options.close) {
				const closeContainer = document.createElement("div"); //																	<div class="slds-notify__close" style="top: 0;">
				closeContainer.style.top = "0px";
				closeContainer.classList.add("slds-notify__close");

				const closeButton = document.createElement("button"); // 																				<button class="slds-button slds-button_icon slds-button_icon-inverse" title="Close" onclick="TEST()" style="font-size: xx-large">x</button>
				closeButton.classList.add("slds-button", "slds-button_icon", "slds-button_icon-inverse");
				closeButton.setAttribute("title", "Close");
				closeButton.addEventListener("click", () => {
					toast.hideToast();
				});
				closeButton.style.fontSize = "xx-large";
				closeButton.style.textDecoration = "unset";
				closeButton.textContent = "x";
				closeContainer.appendChild(closeButton);
				notify.appendChild(closeContainer); //																						</div>
			}
			//																													</div>
			//																										</div>

			return container;
		};

		const options = {
			text: message,
			gravity: "top",
			position: "center",
			stopOnFocus: false, // To stop timer when hovered over the toast (Only if duration is set)
		};

		switch (variant) {
			case "success":
				options.theme = "slds-theme_success";
				options.style = { background: "var(--slds-g-color-success-base-50,#2e844a)" };
				break;
			case "warning":
				options.theme = "slds-theme_warning";
				options.style = { background: "var(--slds-g-color-warning-base-60,#fe9339)" };
				break;
			case "error":
				options.theme = "slds-theme_error";
				options.style = { background: "var(--slds-g-color-error-base-40,#ea001e)" };
				break;
			default:
				// info
				options.theme = "slds-theme_info";
				options.style = { background: "var(--slds-g-color-neutral-base-50,#747474)" };
				break;
		}

		switch (mode) {
			case "pester":
				// Remains visible for 3 seconds, can't be closed
				options.duration = 3000;
				options.close = false;
				break;
			case "sticky":
				// Remains visible until the user clicks the close button.
				options.duration = -1;
				options.close = true;
				break;
			default:
				// dismissible
				// Remains visible until the user clicks the close button or 3 seconds has elapsed, whichever comes first.
				options.duration = 3000;
				options.close = true;
				break;
		}
		if (options.close) {
			options.onClick = () => toast.hideToast();
		}
		options.node = makeToast();
		options.close = false;

		toast = Toastify(options).showToast();
		// if (options.close) {
		// 	// Fix the button to close the toast
		// 	const button = toast.toastElement.children[1];
		// 	button.innerText = "Close";
		// 	button.style.padding = "0px";
		// 	button.style.color = "white";
		// 	button.style.opacity = "unset";
		// 	button.style.textDecoration = "underline";
		// }
	}

	static reportError({ title = "An error has occurred", error }) {
		console.error(error);
		// debugger;

		let message;
		if (typeof error === "string") {
			message = error;
		} else if (typeof error?.message === "string") {
			message = error.message;
		} else if (typeof error?.errorMessage === "string") {
			message = error.errorMessage;
		} else {
			message = JSON.stringify(error);
		}
		this.showNotification({ title, message, variant: "error", mode: "sticky" });
	}

	static calculateDuration({ startAt, endAt }) {
		const toString = (withLeadingZeroes, params) => {
			let { factor, rounding } = params;
			let { days, hours, minutes, seconds, milliseconds } = params.diff;

			// let output = `${days > 0 ? days + "d " : ""}${("00" + hours).slice(-2)}h ${("00" + minutes).slice(-2)}m ${("00" + seconds).slice(-2)}s`;
			let output = "";

			let strDays = "";
			let strHours = "";
			let strMinutes = "";
			let strSeconds = "";
			let strMilliseconds = "";
			if (withLeadingZeroes) {
				if (days > 0) {
					strDays = `${days} d`;
				}
				if (hours > 0 || days > 0) {
					strHours = `${("00" + hours).slice(-2)} h`;
				}
				strMinutes = `${("00" + minutes).slice(-2)} m`;
				strSeconds = `${("00" + seconds).slice(-2)} s`;
				strMilliseconds = `${("000" + milliseconds).slice(-3)} ms`;
			} else {
				if (days > 0) {
					strDays = `${days} d`;
				}
				if (hours > 0 || days > 0) {
					strHours = `${hours} h`;
				}
				strMinutes = `${minutes} m`;
				strSeconds = `${seconds} s`;
				strMilliseconds = `${milliseconds} ms`;
			}
			switch (rounding) {
				case "MILLIS": {
					output = `${strDays} ${strHours} ${strMinutes} ${strSeconds} ${strMilliseconds}`;
					break;
				}
				case "SEC": {
					output = `${strDays} ${strHours} ${strMinutes} ${strSeconds}`;
					break;
				}
				case "MIN": {
					output = `${strDays} ${strHours} ${strMinutes}`;
					break;
				}
				case "HOUR": {
					output = `${strDays} ${strHours}`;
					break;
				}
				case "DAY": {
					output = `${strDays}`;
					break;
				}
				default:
					break;
			}

			if (days + hours + minutes + seconds > 0 && factor < 0) {
				output = `- ${output}`;
			}
			return output.trim();
		};

		const parse = ({ start, end, rounding }) => {
			let output = {
				total: 0,
				diff: {},
				rounding,
				values: {},
				factor: start <= end ? 1 : -1,
			};

			switch (rounding) {
				case "MILLIS": {
					rounding = 1 / 1000;
					break;
				}
				case "SEC": {
					rounding = 1;
					break;
				}
				case "MIN": {
					rounding = 1 * 60;
					break;
				}
				case "HOUR": {
					rounding = 1 * 60 * 60;
					break;
				}
				case "DAY": {
					rounding = 1 * 60 * 60 * 24;
					break;
				}
				default:
					break;
			}

			// Substracting 2 dates returns number of milliseconds
			let delta = Math.abs(start - end) / 1000;

			// Apply rounding
			delta = Math.round(delta / rounding) * rounding;
			output.total = delta;

			// calculate (and subtract) whole days
			output.diff.days = Math.floor(delta / 86400);
			delta -= output.diff.days * 86400;

			// calculate (and subtract) whole hours
			output.diff.hours = Math.floor(delta / 3600) % 24;
			delta -= output.diff.hours * 3600;

			// calculate (and subtract) whole minutes
			output.diff.minutes = Math.floor(delta / 60) % 60;
			delta -= output.diff.minutes * 60;

			// calculate whole seconds and subtract for milliseconds
			output.diff.seconds = Math.floor(delta);
			output.diff.milliseconds = Math.round((delta - Math.floor(delta)) * 1000);

			// Values
			output.values.seconds = output.total;
			output.values.minutes = output.values.seconds / 60;
			output.values.hours = output.values.minutes / 60;
			output.values.days = output.values.hours / 24;

			// Make string
			output.toString = function (withLeadingZeroes = false) {
				let string = toString(withLeadingZeroes, this);
				return string;
			};

			return output;
		};

		let output = null;
		startAt = new Date(startAt);
		endAt = new Date(endAt);
		if (!isNaN(startAt.getTime()) && !isNaN(endAt.getTime())) {
			output = {
				milliseconds: {},
				seconds: {},
				minutes: {},
				hours: {},
				days: {},
			};

			output.milliseconds = parse({ start: startAt, end: endAt, rounding: "MILLIS" });
			output.seconds = parse({ start: startAt, end: endAt, rounding: "SEC" });
			output.minutes = parse({ start: startAt, end: endAt, rounding: "MIN" });
			output.hours = parse({ start: startAt, end: endAt, rounding: "HOUR" });
			output.days = parse({ start: startAt, end: endAt, rounding: "DAY" });
		}

		return output;
	}
}
