import Utils from "lwc/utils/utils.js";
import { LightningElement, track } from "lwc";
import { imperativeData } from "lwc/utilsData/utilsData.js";
import scannerRegistering from "lwc/scannerRegistering/scannerRegistering.js";
import utilsSelectLocation from "lwc/utilsSelectLocation/utilsSelectLocation.js";

export default class ScannerHome extends LightningElement {
	isLoading = true;
	isVideoReady = false;
	@track room = {};
	@track event = {};
	@track scanner = {};
	computerNumber = null;
	promptedLocation = false;

	connectedCallback() {
		this.promptedLocation = false;
		this.scanner = getJsonFromCookie("scanner");
		this.event = getJsonFromCookie("event");
		this.room = getJsonFromCookie("room");
		this.promptForLocation();
	}

	onVideoReady() {
		this.isLoading = false;
		this.isVideoReady = true;
	}

	async onQRCode(event) {
		if (this.computerNumber) {
			const result = await scannerRegistering.open({
				size: "small",
				disableClose: true,
				computerData: {
					...event.detail,
					roomId: this.room.value,
					roomName: this.room.label,
					scannerId: this.scanner.value,
					scannerName: this.scanner.label,
					computerNumber: this.computerNumber,
				},
			});
			if (result.isSuccess) {
				this.computerNumber++;
				this.updateScanner();
			}
			this.refs.scanner.isProcessing = false;
		} else {
			this.refs.scanner.isProcessing = false;
		}
	}

	onChangeLocation() {
		this.promptForLocation();
	}

	timer = null;
	onComputerNumberChange(event) {
		this.computerNumber = event.target.value;
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			this.updateScanner();
		}, 1e3);
	}

	async promptForLocation() {
		const focusOnNumber = () => {
			setTimeout(() => {
				const computerNumber = this.template.querySelector(`lightning-input[data-computer-number]`);
				if (computerNumber) {
					computerNumber.focus();
				} else {
					focusOnNumber();
				}
			}, 1e1);
		};
		this.isLoading = false;
		const result = await utilsSelectLocation.open({
			size: "small",
			disableClose: true,
			config: {
				room: this.room,
				event: this.event,
				scanner: this.scanner,
				showScannerName: true,
			},
		});
		if (!this.isVideoReady) {
			this.isLoading = true;
		}
		window.scrollTo(0, 0);
		this.promptedLocation = true;
		focusOnNumber();
		Object.assign(this.room, result.room);
		Object.assign(this.event, result.event);
		Object.assign(this.scanner, result.scanner);
		writeCookie("scanner", JSON.stringify(this.scanner));
		writeCookie("event", JSON.stringify(this.event));
		writeCookie("room", JSON.stringify(this.room));
	}

	async updateScanner() {
		try {
			this.isLoading = true;
			await imperativeData({ operation: "registerScannerRoom", scanner: this.scanner, room: this.room, nextNumber: this.computerNumber });
			this.isLoading = false;
		} catch (error) {
			this.isLoading = false;
			Utils.reportError({ error });
		}
	}
}
