import Utils from "lwc/utils/utils.js";
import { LightningElement } from "lwc";
import { imperativeData } from "lwc/utilsData/utilsData.js";

const COLUMNS = [
	{ label: "Header", fieldName: "header" },
	{ label: "Count", fieldName: "count", type: "number", cellAttributes: { alignment: "left" } },
	{ label: "Count ✅", fieldName: "countConnected", type: "number", cellAttributes: { alignment: "left" } },
	{ label: "Count ❌", fieldName: "countDisconnected", type: "number", cellAttributes: { alignment: "left" } },
	{ label: "Status", fieldName: "emoji" },
	{ label: "Room", fieldName: "RoomName" },
	{ label: "Number", fieldName: "Number__c" },
	{ label: "Version", fieldName: "Version__c" },
	{ label: "WI-FI", fieldName: "WIFI_SSID__c" },
	{ label: "Mac Id", fieldName: "MacId__c" },
	{ label: "Computer Id", fieldName: "Id" },
];

export default class AdminCounts extends LightningElement {
	isLoading = false;
	computers = [];
	computersBy = {
		room: {},
		wifi: {},
		event: {},
		scanner: {},
		version: {},
	};
	tgData = [];
	tgColumns = COLUMNS;

	async connectedCallback() {
		await this.getData();
	}

	async onRefreshClick() {
		await this.getData();
	}

	async getData() {
		try {
			this.isLoading = true;
			const computers = await imperativeData({ operation: "adminFindComputers", location: { everyComputer: true, querySF: true } });
			this.processData(computers);
			this.isLoading = false;
		} catch (error) {
			this.isLoading = false;
			Utils.reportError({ error });
		}
	}

	processData(computers) {
		this.groups = [];
		this.computersBy = {
			room: {},
			wifi: {},
			event: {},
			scanner: {},
			version: {},
		};
		this.computers = computers
			.map((computer) => {
				computer = { ...computer, ...computer.record };
				computer.RoomName = computer.Room__r.Name;
				computer.EventName = computer.Room__r.Event__r.Name;
				computer.ScannerName = computer.Scanner__r.Name;
				computer.isConnected = !!computer.socketId;
				computer.emoji = computer.isConnected ? "✅" : "❌";
				return computer;
			})
			.sort((a, b) => {
				let fields = ["emoji", "RoomName", "Version__c", "WIFI_SSID__c", "MacId__c", "Id"];
				for (let field of fields) {
					if (a[field] < b[field]) {
						return -1;
					} else if (a[field] > b[field]) {
						return 1;
					} else {
						// Keep Looping
					}
				}
				return 0;
			})
			.map((computer) => {
				this.groupBy({ computer, groupName: "room", groupValue: computer.RoomName });
				this.groupBy({ computer, groupName: "wifi", groupValue: computer.WIFI_SSID__c });
				this.groupBy({ computer, groupName: "scanner", groupValue: computer.ScannerName });
				this.groupBy({ computer, groupName: "version", groupValue: computer.Version__c });
				this.groupBy({ computer, groupName: "event", groupValue: computer.EventName });
				return computer;
			});

		// Make table data
		this.tgData = [];
		this.tgData = Object.keys(this.computersBy)
			.sort()
			.map((groupBy) => {
				let groups = this.computersBy[groupBy];
				let countPerGroup = 0;
				let countPerGroupConnected = 0;
				let countPerGroupDisconnected = 0;
				return {
					KEY: `${groupBy}|`,
					header: groupBy,
					_children: Object.keys(groups)
						.sort()
						.map((groupValue) => {
							let countPerGroupValue = groups[groupValue].length;
							let countPerGroupValueConnected = 0;
							let countPerGroupValueDisconnected = 0;

							countPerGroup += countPerGroupValue;
							return {
								KEY: `${groupBy}|${groupValue}`,
								header: groupValue,
								_children: groups[groupValue].map((computer) => {
									if (computer.isConnected) {
										countPerGroupConnected++;
										countPerGroupValueConnected++;
									} else {
										countPerGroupDisconnected++;
										countPerGroupValueDisconnected++;
									}

									return {
										KEY: `${groupBy}|${groupValue}|${computer.Id}`,
										header: computer.Id,
										...computer,
									};
								}),
								count: countPerGroupValue,
								countConnected: countPerGroupValueConnected,
								countDisconnected: countPerGroupValueDisconnected,
							};
						}),
					count: countPerGroup,
					countConnected: countPerGroupConnected,
					countDisconnected: countPerGroupDisconnected,
				};
			});
	}

	groupBy({ computer, groupName, groupValue }) {
		// Group computer
		let list = [];
		if (this.computersBy[groupName][groupValue]) {
			list = this.computersBy[groupName][groupValue];
		}
		list.push(computer);
		this.computersBy[groupName][groupValue] = list;
	}
}
