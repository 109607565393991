import Utils from "lwc/utils/utils.js";
import { api, track, wire } from "lwc";
import LightningModal from "lightning/modal";
import { wiredData } from "lwc/utilsData/utilsData.js";

export default class UtilsSelectLocation extends LightningModal {
	_config = {};
	qrCode = {};
	isLoading = true;

	@api
	get config() {
		return this._config;
	}
	set config(value) {
		if (value) {
			this._config = value;
		}
	}

	get continueDisabled() {
		let hasCompletedData = false;
		// Code has been scanned
		return !hasCompletedData;
	}

	onVideoReady() {
		this.isLoading = false;
	}

	onQRCode(event) {
		this.qrCode = event.detail;
		this.disableClose = false;
		this.close({ qrCode: this.qrCode });
	}

	onCancelClick() {
		this.disableClose = false;
		this.close(null);
	}
}
