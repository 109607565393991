import LightningModal from "lightning/modal";
import { imperativeData } from "lwc/utilsData/utilsData.js";

export default class AppLogin extends LightningModal {
	password = "";
	isLoading = false;
	invalidPassword = false;

	onPasswordChange(event) {
		this.password = event.target.value;
	}

	onPasswordKeyUp(event) {
		if (event.key === "Enter") {
			this.onLoginClick();
		}
	}

	async onLoginClick() {
		this.isLoading = true;
		this.invalidPassword = false;
		try {
			const response = await imperativeData({ operation: "login", password: this.password });
			this.isLoading = false;
			console.log(response);
			if (response.isValidPassword) {
				this.disableClose = false;
				this.close({ isLoggedIn: true });
			} else {
				throw "Failed to login";
			}
		} catch (error) {
			this.isLoading = false;
			this.invalidPassword = true;
		}
	}
}
