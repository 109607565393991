const callout = async ({ config, request }) => {
	Object.assign(request, {
		headers: {
			"Content-Type": "application/json",
		},
		method: "POST",
		url: "/ws",
		output: {},
	});

	const calloutMessage = `Callout ${request.url} with ${JSON.stringify(config, null, 1)}`;
	let output = {
		config,
		request,
	};
	request.isValid = false;

	try {
		let response;
		let text = "";

		// Make contact :-)
		try {
			response = await fetch(request.url, request);
			request.status = response.status;
			request.statusText = response.statusText;
		} catch (ex) {
			output.errorMessage = `${config.operation} failed. Could not connect to server. ${calloutMessage}`;
			throw output;
		}

		// Get simple text data
		try {
			text = await response.text();
			request.output.text = text;
		} catch (ex) {
			output.errorMessage = `${config.operation} failed. Unable to process server response. ${calloutMessage}`;
			throw output;
		}

		// Parse it
		try {
			request.output.json = JSON.parse(request.output.text);
			delete request.output.text;
			Object.assign(request.output, request.output.json);
			delete request.output.json;
		} catch (ex) {
			output.errorMessage = `${config.operation} failed. Unable to process server JSON response ${text}. ${calloutMessage}`;
			throw output;
		}

		// Validate status
		if (request.status >= 200 && request.status < 300) {
			request.isValid = true;
		} else {
			output.errorMessage = `${config.operation} failed. Responded with ${request.statusText} (HTTP: ${request.status}). ${text}. ${calloutMessage}`;
			throw output;
		}

		if (!request.isValid) {
			debugger;
			throw output;
		}
	} catch (error) {
		request.isValid = false;
		if (!request.output.error) {
			request.output.error = {
				errorMessage: output.errorMessage,
			};
		}
		throw output;
	}

	return output;
};

export class wiredData {
	connected = false;
	usesCache = false;
	dataCallback = null;
	responses = new Map();

	constructor(dataCallback) {
		console.log("WIRED_CONSTRUCTOR");
		this.dataCallback = dataCallback;
		// I do not understand why Salesforce is doing this with on-platform regular LWC!
		// this.dataCallback({ data: undefined, error: undefined });
	}

	connect(data) {
		console.log("WIRED_CONNECT", data);
		this.connected = true;
	}

	disconnect(data) {
		console.log("WIRED_DISCONNECT", data);
		this.connected = false;
	}

	async update(config) {
		console.log("WIRED_UPDATE", config);
		if (!this.connected) {
			debugger;
			return;
		}

		try {
			await this.requestData({ config });
		} catch (error) {
			this.dataCallback({ error });
		}
	}

	async requestData({ config }) {
		const hasMissingData = () => {
			let tmp = { ...config };
			delete tmp.operation;
			request.isValid = !Object.keys(tmp).some((key) => tmp[key] === null || tmp[key] === undefined);
			return !request.isValid;
		};

		const request = {
			isValid: true,
		};

		if (hasMissingData()) {
			console.log("WIRED_UPDATE_INCOMPLETE", config);
			return;
		}

		if (config.operation) {
			request.body = JSON.stringify(config, null, 1);
		} else {
			this.dataCallback({ error: { message: "Request must have an operation", ...config } });
		}

		// Notify with cached data
		if (request.isValid && this.usesCache) {
			if (this.responses.has(request.body)) {
				const cache = this.responses.get(request.body);
				console.log("WIRED_UPDATE_CACHE", config, request, cache);
				this.dataCallback(cache);
			}
		}

		// Update cached data, and notify if changed
		if (request.isValid) {
			let newData;
			let oldData = null;
			let hasFailed = true;
			if (this.responses.has(request.body)) {
				oldData = this.responses.get(request.body);
			}
			try {
				const calloutResponse = await callout({ config, request });
				newData = calloutResponse.request.output;
				hasFailed = false;
			} catch (ex) {
				newData = ex.request.output;
				hasFailed = true;
			}

			if (newData !== oldData) {
				console.log("WIRED_UPDATE_SERVER", config, request, newData);
				if (hasFailed) {
					this.responses.delete(request.body);
				} else {
					this.responses.set(request.body, newData);
				}
				this.dataCallback(newData);
			}
		}
	}
}

export const imperativeData = async (config) => {
	const request = {
		isValid: true,
		body: JSON.stringify(config, null, 1),
	};
	try {
		const calloutResponse = await callout({ config, request });
		console.log(calloutResponse);
		return calloutResponse.request.output.data;
	} catch (error) {
		throw error.request.output.error;
	}
};
