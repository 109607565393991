import Utils from "lwc/utils/utils.js";
import { LightningElement, wire } from "lwc";
import { imperativeData, wiredData } from "lwc/utilsData/utilsData.js";

const gridColumns = [
	{ type: "text", fieldName: "name", label: "Name" },
	{ type: "text", fieldName: "connected", label: "Conected", initialWidth: 150, cellAttributes: { alignment: "right" } },
	{ type: "text", fieldName: "disconnected", label: "Disconnected", initialWidth: 150, cellAttributes: { alignment: "right" } },
];

const testColumns = [
	{ type: "text", fieldName: "MacId", label: "MacId", sortable: true, initialWidth: 350 },
	{ type: "text", fieldName: "roomName", label: "Room Name", sortable: true },
	{ type: "number", fieldName: "computerNumber", label: "Computer Number", sortable: true },
	{ type: "text", fieldName: "version", label: "Version", sortable: true, initialWidth: 150, cellAttributes: { alignment: "right" } },
	// {
	// 	type: "date",
	// 	fieldName: "startDTTM",
	// 	label: "Start DTTM",
	// 	sortable: true,
	// 	typeAttributes: {
	// 		year: "numeric",
	// 		month: "short",
	// 		day: "2-digit",
	// 		hour: "2-digit",
	// 		minute: "2-digit",
	// 	},
	// },
	{
		type: "date",
		fieldName: "endDTTM",
		label: "DTTM",
		sortable: true,
		typeAttributes: {
			year: "numeric",
			month: "short",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
		},
	},
	// { type: "number", fieldName: "countErrors", label: "Errors", sortable: true, initialWidth: 100 },
	// { type: "number", fieldName: "countWarnings", label: "Warnings", sortable: true, initialWidth: 100 },
	// { type: "boolean", fieldName: "isDone", label: "Done", sortable: true, initialWidth: 100 },
	// { type: "text", fieldName: "lastMessage", label: "Status", sortable: true, initialWidth: 100 },
	{ type: "text", fieldName: "status", label: "Status", sortable: true },
];

export default class AdminMonitor extends LightningElement {
	rooms = null;
	events = null;
	gridData = [];
	testData = [];
	computers = [];
	isLoading = true;
	gridColumns = gridColumns;
	testColumns = testColumns;
	displayInactiveRoom = false;
	displayDisconnected = false;

	defaultSortDirection = "asc";
	sortDirection = "asc";
	sortedBy;

	@wire(wiredData, { operation: "getEvents", includeStorage: true })
	getEvents({ data, error }) {
		if (data) {
			this.events = data.records;
			this.getData();
		} else if (error) {
			this.isLoading = false;
			Utils.reportError({ error });
		}
	}

	@wire(wiredData, { operation: "getRooms", includeStorage: true })
	getRooms({ data, error }) {
		if (data) {
			this.rooms = data.records;
			this.getData();
		} else if (error) {
			this.isLoading = false;
			Utils.reportError({ error });
		}
	}

	get displayInactiveRoomIcon() {
		return this.displayInactiveRoom ? "utility:check" : "utility:close";
	}

	get displayDisconnectedIcon() {
		return this.displayDisconnected ? "utility:check" : "utility:close";
	}

	get displayInactiveRoomLabel() {
		return this.displayInactiveRoom ? "Hide Inactive Rooms" : "Show All Rooms";
	}

	get displayDisconnectedLabel() {
		return this.displayDisconnected ? "Hide Connected" : "Show All Computers";
	}

	onDisplayInactiveRoomClick() {
		this.isLoading = true;
		this.displayInactiveRoom = !this.displayInactiveRoom;
		this.getData();
	}

	onDisplayDisconnectedClick() {
		this.isLoading = true;
		this.displayDisconnected = !this.displayDisconnected;
		this.getData();
	}

	onRefreshClick() {
		this.isLoading = true;
		this.getData();
	}

	sortBy(field, reverse, primer) {
		const key = primer
			? function (x) {
					return primer(x[field]);
			  }
			: function (x) {
					return x[field];
			  };

		return function (a, b) {
			a = key(a);
			b = key(b);
			return reverse * ((a > b) - (b > a));
		};
	}

	onHandleSort(event) {
		const { fieldName: sortedBy, sortDirection } = event.detail;
		const cloneData = [...this.testData];

		cloneData.sort(this.sortBy(sortedBy, sortDirection === "asc" ? 1 : -1));
		this.testData = cloneData;
		this.sortDirection = sortDirection;
		this.sortedBy = sortedBy;
	}

	async getData() {
		if (this.events && this.rooms) {
			try {
				this.isLoading = true;
				const computers = await imperativeData({ operation: "adminFindComputers", location: { everyComputer: true } });
				this.computers = computers;
				this.processData();
				this.isLoading = false;
			} catch (error) {
				this.isLoading = false;
				Utils.reportError({ error });
			}
		}
	}

	processData() {
		if (this.events && this.rooms && this.computers) {
			console.log("Events: ", this.events);
			console.log("Rooms: ", this.rooms);
			console.log("Computers: ", this.computers);

			this.gridData = [];
			const eventsMap = new Map();
			const roomsMap = new Map();
			this.events.forEach((event) => {
				const eventObj = {
					id: event.Id,
					name: event.Name,
					_children: [],
					roomsMap: new Map(),
					connectedCount: 0,
					disconnectedCount: 0,
					get connected() {
						return `${this.connectedCount}`;
					},
					get disconnected() {
						return `${this.disconnectedCount}`;
					},
				};
				eventsMap.set(event.Id, eventObj);
				this.gridData.push(eventObj);
			});

			this.rooms.forEach((room) => {
				const event = eventsMap.get(room.Event__c);
				if (event) {
					const roomObj = {
						event,
						id: room.Id,
						name: room.Name,
						isActive: room.IsActive__c,
						_children: [],
						computersMap: new Map(),
						connectedCount: 0,
						disconnectedCount: 0,
						get connected() {
							return `${this.connectedCount}`;
						},
						get disconnected() {
							return `${this.disconnectedCount}`;
						},
					};
					event._children.push(roomObj);
					event.roomsMap.set(room.Id, roomObj);
					roomsMap.set(room.Id, roomObj);
				} else {
					// Event not found, maybe storage
					debugger;
				}
			});

			this.testData = [];
			this.computers.forEach((computer) => {
				const room = roomsMap.get(computer.roomId);
				if (room) {
					const event = room.event;
					const computerObj = Object.assign({}, computer, {
						name: `${computer.roomName} #${computer.computerNumber} (${computer.MacId})`,
						id: computer.Id,
						eventId: event.id,
						connected: "",
						disconnected: "",
					});
					if (computer.socketId) {
						computerObj.connected = "✅";
						room.connectedCount++;
						if (this.displayInactiveRoom || room.isActive) {
							event.connectedCount++;
						}
					} else {
						computerObj.disconnected = "❌";
						room.disconnectedCount++;
						if (this.displayInactiveRoom || room.isActive) {
							event.disconnectedCount++;
						}
					}
					if (!computer.socketId || this.displayDisconnected) {
						room._children.push(computerObj);
					}
				} else {
					// Room not found, maybe storage
					debugger;
				}

				if (computer.testStatus) {
					let cmp = Object.assign({}, computer, computer.testStatus);
					cmp.status = "";
					if (cmp.lastMessage === "DONE") {
						cmp.status = `${cmp.countErrors} Error${cmp.countErrors !== 1 ? "s" : ""}`;
					} else {
						cmp.status = cmp.lastMessage;
					}
					delete cmp.testStatus;
					if (this.displayInactiveRoom || cmp.roomActive) {
						this.testData.push(cmp);
					}
				}
			});

			// Filter events and rooms
			if (!this.displayInactiveRoom) {
				// Must filter out inactive rooms
				this.gridData = this.gridData.filter((event) => {
					event._children = event._children.filter((room) => room.isActive);
					if (event._children.length === 0) {
						delete event._children;
					}
					return event._children;
				});
			}

			// Fix computers within room
			roomsMap.forEach((room) => {
				if (room._children.length > 0) {
					// Sort by name
					room._children.sort((a, b) => (a.name < b.name ? -1 : 1));
				} else {
					// Do not show option to open children
					delete room._children;
				}
			});
		} else {
			debugger;
		}
	}
}
