import Utils from "lwc/utils/utils.js";
import { LightningElement, wire } from "lwc";
import LightningConfirm from "lightning/confirm";
import { imperativeData, wiredData } from "lwc/utilsData/utilsData.js";
import adminHeaderScanner from "lwc/adminHeaderScanner/adminHeaderScanner.js";
import utilsSelectLocation from "lwc/utilsSelectLocation/utilsSelectLocation.js";

export default class AdminHeader extends LightningElement {
	room = {};
	SSIDs = [];
	event = {};
	SSID = null;
	number1 = {};
	number2 = {};
	option = null;
	computerNumbers = null;
	computersInRoom = {
		mapAll: new Map(),
		mapConnected: new Map(),
		connected: [],
		disconnected: [],
		firstNumber: null,
		lastNumber: null,
	};
	isLoading = false;

	@wire(wiredData, { operation: "getSSIDs" })
	getSSIDs({ data, error }) {
		if (data) {
			this.SSIDs = data.records.map((SSID) => {
				SSID = SSID.WIFI_SSID__c;
				SSID = SSID ? SSID : "UNKNOWN";
				return { label: SSID, value: SSID };
			});
		} else if (error) {
			this.isLoading = false;
			Utils.reportError({ error });
		}
	}

	constructor() {
		super();
		this.event = getJsonFromCookie("event");
		this.room = getJsonFromCookie("room");
		// this.SSID = getJsonFromCookie("SSID")?.value;
		this.findComputersInRoom();
	}

	get options() {
		return [
			{ label: "One Random", value: "ONE_RANDOM" },
			{ label: "One Specific", value: "ONE_SPECIFIC" },
			{ label: "Multiple Random", value: "MULTIPLE_RANDOM" },
			{ label: "Multiple Range", value: "MULTIPLE_RANGE" },
			{ label: "All", value: "All" },
		];
	}

	get optionsDisabled() {
		return this.computersInRoom.connected.length <= 0;
	}

	get controlComputersDisabled() {
		let isValid = true;

		if (!this.event.value) isValid = false;
		if (!this.room.value) isValid = false;
		if (!this.option) isValid = false;
		if (this.number1.isVisible) {
			if (!this.number1.isValid) isValid = false;
		}
		if (this.number2.isVisible) {
			if (!this.number2.isValid) isValid = false;
		}

		return !isValid;
	}

	get classSelected() {
		let value = null;
		let number = this.computerNumbers.length;
		if (this.computerNumbers) {
			if (number <= 1) {
				value = "green";
			} else if (number <= 2) {
				value = "yellow";
			} else {
				value = "red";
			}
		}
		return value;
	}

	get hasComputerNumbers() {
		return this.computerNumbers?.length > 0;
	}

	get isStorageResetable() {
		let storage = "STORAGE";
		let output = false;
		try {
			output = this.event.label.toUpperCase() === storage && this.room.label.toUpperCase() === storage && this.option === "All" && this.computerNumbers.length > 0;
		} catch (ex) {
			output = false;
		}
		return output;
	}

	onSSIDChange(event) {
		this.SSID = event.detail.value;
		this.event.value = "SSID";
		this.room.value = "SSID";
		writeCookie("SSID", JSON.stringify({ value: this.SSID }));
		this.findComputersInRoom();
	}

	async onChangeLocationClick() {
		const result = await utilsSelectLocation.open({
			size: "small",
			disableClose: true,
			config: {
				room: this.room,
				event: this.event,
				includeStorage: true,
				showScannerName: false,
			},
		});
		this.room = result.room;
		this.event = result.event;
		writeCookie("event", JSON.stringify(this.event));
		writeCookie("room", JSON.stringify(this.room));
		// this.updateLocation();
		this.findComputersInRoom();
	}

	async onScanClick() {
		const result = await adminHeaderScanner.open({
			size: "small",
			disableClose: true,
		});
		if (result) {
			try {
				this.isLoading = true;
				const response = await imperativeData({ operation: "adminScannedQRCode", ...result.qrCode });
				console.log(result, response);
				this.event = {
					value: response.Room__r.Event__c,
					label: response.Room__r.Event__r.Name,
				};
				this.room = {
					value: response.Room__c,
					label: response.Room__r.Name,
				};
				writeCookie("event", JSON.stringify(this.event));
				writeCookie("room", JSON.stringify(this.room));
				this.resetComputerNumbers();
				await this.findComputersInRoom();
				this.option = "ONE_SPECIFIC";
				this.validateOption();
				this.number1.value = response.Number__c;
				this.onControlComputersClick();
				this.isLoading = false;
			} catch (error) {
				this.isLoading = false;
				Utils.reportError({ error });
			}
		}
	}

	onOptionChanged(event) {
		this.resetComputerNumbers();
		this.option = event.target.value;
		this.validateOption();
	}

	onControlComputersClick() {
		let computerNumbers = [];

		switch (this.option) {
			case "ONE_RANDOM":
			case "MULTIPLE_RANDOM": {
				let count = 1;
				let computersConnected = [...this.computersInRoom.connected];
				if (this.option === "MULTIPLE_RANDOM") {
					count = this.number1.value;
				}
				while (count > 0 && computersConnected.length > 0) {
					let index = Math.floor(Math.random() * computersConnected.length);
					computerNumbers.push(computersConnected.splice(index, 1)[0]);
					count--;
				}
				break;
			}
			case "ONE_SPECIFIC": {
				if (this.computersInRoom.mapConnected.has(this.number1.value)) {
					computerNumbers.push(this.number1.value);
				}
				break;
			}
			case "MULTIPLE_RANGE": {
				let start = this.number1.value;
				let end = this.number2.value;
				computerNumbers = this.computersInRoom.connected.filter((computerNumber) => computerNumber >= start && computerNumber <= end);
				break;
			}
			case "All": {
				computerNumbers = [...this.computersInRoom.connected];
				break;
			}
			default:
				debugger;
				break;
		}

		computerNumbers.sort((a, b) => (a < b ? -1 : 1));
		this.computerNumbers = computerNumbers;
		const computers = computerNumbers.map((computerNumber) => this.computersInRoom.mapConnected.get(computerNumber));
		console.log("Computers to control:");
		computers.forEach((computer) => console.log(`${computer.roomName} #${computer.computerNumber}`, computer));
		this.dispatchEvent(new CustomEvent("selected", { detail: computers }));
	}

	onRefreshClick() {
		this.resetComputerNumbers();
		this.findComputersInRoom();
	}

	onNumber1Change(event) {
		this.resetComputerNumbers();
		event.target.reportValidity();
		this.number1 = { ...this.number1 };
		this.number1.isValid = event.target.checkValidity();
		if (this.number1.isValid) {
			this.number1.value = Number(event.target.value);
			this.number2.min = this.number1.value;
		}
	}

	onNumber2Change(event) {
		this.resetComputerNumbers();
		event.target.reportValidity();
		this.number2 = { ...this.number2 };
		this.number2.isValid = event.target.checkValidity();
		if (this.number2.isValid) {
			this.number2.value = Number(event.target.value);
			this.number1.max = this.number2.value;
		}
	}

	async onResetStorage() {
		const confirmed = await LightningConfirm.open({
			value: "Are you sure?",
			message: "You are about to delete all the STORAGE registrations",
			variant: "header",
		});
		if (confirmed) {
			this.dispatchEvent(new CustomEvent("actionrequested", { detail: { option: "restart", value: "allStorageRegistrations" } }));
		}
	}

	async findComputersInRoom() {
		const SSID = this.SSID;
		const eventId = this.event.value;
		const roomId = this.room.value;

		if (eventId && roomId) {
			try {
				this.isLoading = true;
				const computers = await imperativeData({
					operation: "adminFindComputers",
					location: {
						eventId,
						roomId,
						SSID,
						doAllComputersInRoom: true,
					},
				});
				this.computersInRoom = {
					mapAll: new Map(),
					mapConnected: new Map(),
					connected: [],
					disconnected: [],
					firstNumber: null,
					lastNumber: null,
				};
				computers.forEach((computer) => {
					delete computer.testStatus;
					this.computersInRoom.mapAll.set(computer.computerNumber, computer);
					if (computer.socketId) {
						this.computersInRoom.connected.push(computer.computerNumber);
						this.computersInRoom.mapConnected.set(computer.computerNumber, computer);
						if (!this.computersInRoom.firstNumber || this.computersInRoom.firstNumber > computer.computerNumber) {
							this.computersInRoom.firstNumber = computer.computerNumber;
						}
						if (!this.computersInRoom.lastNumber || this.computersInRoom.lastNumber < computer.computerNumber) {
							this.computersInRoom.lastNumber = computer.computerNumber;
						}
					} else {
						this.computersInRoom.disconnected.push(computer.computerNumber);
					}
				});
				this.computersInRoom.connected.sort((a, b) => (a < b ? -1 : 1));
				this.computersInRoom.disconnected.sort((a, b) => (a < b ? -1 : 1));

				// this.option = null;
				this.validateOption();

				this.isLoading = false;
			} catch (error) {
				this.isLoading = false;
				Utils.reportError({ error });
			}
		}
	}

	validateOption() {
		switch (this.option) {
			case "ONE_RANDOM": {
				this.number1 = {
					isVisible: false,
				};
				this.number2 = {
					isVisible: false,
				};
				break;
			}
			case "ONE_SPECIFIC": {
				this.number1 = {
					isVisible: true,
					label: "Which one?",
					value: this.computersInRoom.firstNumber,
					min: this.computersInRoom.firstNumber,
					max: this.computersInRoom.lastNumber,
				};
				this.number2 = {
					isVisible: false,
				};
				break;
			}
			case "MULTIPLE_RANDOM": {
				this.number1 = {
					isVisible: true,
					label: "How many?",
					value: 1,
					min: 1,
					max: this.computersInRoom.connected.length,
				};
				this.number2 = {
					isVisible: false,
				};
				break;
			}
			case "MULTIPLE_RANGE": {
				this.number1 = {
					isVisible: true,
					label: "First computer?",
					value: this.computersInRoom.firstNumber,
					min: this.computersInRoom.firstNumber,
					max: this.computersInRoom.lastNumber,
				};
				this.number2 = {
					isVisible: true,
					label: "Last computer?",
					value: this.computersInRoom.firstNumber,
					min: this.computersInRoom.firstNumber,
					max: this.computersInRoom.lastNumber,
				};
				break;
			}
			case "All": {
				this.number1 = {
					isVisible: false,
				};
				this.number2 = {
					isVisible: false,
				};
				break;
			}
			default:
				this.option = null;
				break;
		}
		this.validateNumbers();
	}

	validateNumbers() {
		setTimeout(() => {
			this.number1 = { ...this.number1 };
			this.number1.isValid = false;
			if (this.number1.isVisible) {
				this.number1.isValid = this.refs.number1.checkValidity();
			}

			this.number2 = { ...this.number2 };
			this.number2.isValid = false;
			if (this.number2.isVisible) {
				this.number2.isValid = this.refs.number2.checkValidity();
			}
		}, 0);
	}

	resetComputerNumbers() {
		this.computerNumbers = [];
		this.dispatchEvent(new CustomEvent("selected", { detail: [] }));
	}
}
