import { LightningElement } from "lwc";

const pages = {
	WAIT: "WAIT",
	COLOR: "COLOR",
	QRCODE: "QRCODE",
	UPDATE: "UPDATE",
	COMPUTER_TEST: "COMPUTER_TEST",
};
export default class ElectronHome extends LightningElement {
	color;
	errors;
	progress;
	isConnected = true;
	isInitialized = false;
	currentPage = pages.WAIT;

	get isPageLoading() {
		return this.currentPage === pages.WAIT;
	}
	get isPageColors() {
		return this.currentPage === pages.COLOR;
	}
	get isPageQRCode() {
		return this.currentPage === pages.QRCODE;
	}
	get isPageComputerTest() {
		return this.currentPage === pages.COMPUTER_TEST;
	}
	get isPageUpdate() {
		return this.currentPage === pages.UPDATE;
	}

	async connectedCallback() {
		if (!this.isInitialized) {
			// Register listeners
			window.electronAPI.M2R_Alert(async (event, value) => {
				if (typeof value !== "string") value = JSON.stringify(value);
				alert(value);
			});
			window.electronAPI.M2R_NetworkStatus(async (event, value) => {
				this.processNetworkStatus({ connectionStatus: value });
			});
			window.electronAPI.M2R_ComputerColor(async (event, value) => {
				this.onM2R_ComputerColor({ event, value });
			});
			window.electronAPI.M2R_OpenComputerTest(async (event, value) => {
				if (this.isConnected) {
					this.currentPage = pages.COMPUTER_TEST;
					setTimeout(() => {
						this.refs.computerTester.checkHardware(value);
					}, 0);
				}
			});
			window.electronAPI.M2R_ReportComputerTest(async (event, value) => {
				this.onM2R_ReportComputerTest({ event, value });
			});
			window.electronAPI.M2R_ReportElectronProgress(async (event, value) => {
				this.onM2R_ReportElectronProgress({ event, value });
			});
			this.isInitialized = true;

			const connectionStatus = await window.electronAPI.R2M2R_ValidateNetwork();
			this.processNetworkStatus({ connectionStatus });
		}
	}

	async processNetworkStatus({ connectionStatus }) {
		this.isConnected = connectionStatus.isConnected;
		console.log(connectionStatus);
		if (this.isConnected) {
			this.errros = {};
			if (this.isPageLoading) {
				// If it has not been initialized go to the QRCode page
				this.currentPage = pages.QRCODE;
			}
		} else {
			this.errors = {
				title: "Network",
				subtitle: `Server was not reachable. Please verify the internet connection`,
				servers: connectionStatus.servers,
			};
		}
	}

	onM2R_ComputerColor({ event, value }) {
		if (this.isConnected) {
			this.currentPage = pages.COLOR;
			this.color = value;
		} else {
			debugger;
		}
	}

	onM2R_ReportComputerTest({ event, value }) {
		if (this.isConnected) {
			this.currentPage = pages.COMPUTER_TEST;
			this.progress = value;
		} else {
			debugger;
		}
	}

	onM2R_ReportElectronProgress({ event, value }) {
		if (this.isConnected) {
			this.currentPage = pages.UPDATE;
			this.progress = value;
		} else {
			debugger;
		}
	}
}

/*
progress = {
	lastMessage: "",
	lines: [
		{
			id: 0,
			cssClass: "debug",
			lineNumber: "src/file.mjs:42:42",
			msg: "",
		},
	],
};
*/
