import { LightningElement, api } from "lwc";

export default class UtilsQRCodeScanner extends LightningElement {
	@api isProcessing = false;

	video;
	timer;
	canvas2D;
	qrCode = "";
	isVideoReady = false;
	isInitialized = false;
	isCameraAllowed = false;
	hasRequiredData = false;
	scanMessage = "Waiting...";
	status = "❌ Unable to access video stream (please make sure you have a webcam enabled)";

	connectedCallback() {
		navigator.mediaDevices
			.getUserMedia({ video: { facingMode: "environment" } })
			.then((stream) => {
				console.log(stream);
				this.isCameraAllowed = true;
			})
			.catch(() => {
				alert("Camera is required!");
			});
	}

	renderedCallback() {
		if (!this.isInitialized) {
			if (this.isCameraAllowed) {
				this.video = document.createElement("video");
				this.canvas2D = this.refs.canvas.getContext("2d", { willReadFrequently: true });
				this.isInitialized = true;

				// Use facingMode: environment to attemt to get the front camera on phones
				navigator.mediaDevices
					.getUserMedia({ video: { facingMode: "environment" } })
					.then((stream) => {
						this.video.srcObject = stream;
						this.video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
						this.video.play();
						requestAnimationFrame(this.tick.bind(this));
					})
					.catch((error) => {
						console.log(error);
						debugger;
					});
			}
		}
	}

	tick() {
		const canvas = this.refs.canvas;
		this.status = "⌛ Loading video...";
		if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
			this.status = "✅ Ready to scan...";
			this.portrait = window.matchMedia("(orientation: portrait)").matches;
			if (this.portrait) {
				canvas.height = this.video.videoWidth / 2;
				canvas.width = this.video.videoHeight / 2;
			} else {
				canvas.height = this.video.videoHeight / 2;
				canvas.width = this.video.videoWidth / 2;
			}
			this.canvas2D.drawImage(this.video, 0, 0, canvas.width, canvas.height);
			const imageData = this.canvas2D.getImageData(0, 0, canvas.width, canvas.height);
			const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" });
			this.writeText();

			if (code) {
				this.validateAndNotify(code);
			}
			if (!this.timer) {
				this.timer = setTimeout(() => {
					this.qrCode = "";
					this.scanMessage = "⏳ Waiting for a QR code...";
					// console.log("Messages cleared");
				}, 1e3);
			}
			if (!this.isVideoReady) {
				this.dispatchEvent(new CustomEvent("videoready"));
			}
			this.isVideoReady = true;
		}
		requestAnimationFrame(this.tick.bind(this));
	}

	writeText() {
		// Not implemented on the parent component
	}

	drawLine(begin, end, color) {
		this.canvas2D.beginPath();
		this.canvas2D.moveTo(begin.x, begin.y);
		this.canvas2D.lineTo(end.x, end.y);
		this.canvas2D.lineWidth = 4;
		this.canvas2D.strokeStyle = color;
		this.canvas2D.stroke();
	}

	validateAndNotify(code) {
		let data;
		let qrCode;
		let isValid = false;
		let color = "#FF0000";

		try {
			data = JSON.parse(code.data);
			qrCode = JSON.stringify(data, null, 2);
			if (data.dttm && data.MacId && data.socketId) {
				if (new Date(data.dttm).toJSON() === data.dttm) {
					// Validates that data.dttm is an actual datetime data.
					isValid = true;
				}
			}
		} catch (ex) {
			// debugger;
		}
		clearTimeout(this.timer);
		this.timer = null;
		if (isValid) {
			if (this.qrCode !== qrCode) {
				this.qrCode = qrCode;
				this.scanMessage = `✅ QR Code is valid`;
				// console.log(`DIFFERENT: ${qrCode}`);
				if (!this.isProcessing) {
					this.isProcessing = true;
					this.dispatchEvent(new CustomEvent("qrcode", { detail: data }));
				}
			} else {
				// console.log(`SAME: ${qrCode}`);
			}
			color = "#00FF00";
		} else {
			// console.log(`Invalid code: ${code.data}`);
			this.scanMessage = `❌ QR Code is not valid`;
		}

		if (qrCode) {
			this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, color);
			this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, color);
			this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, color);
			this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, color);
		}
	}
}
