import { LightningElement, api } from "lwc";

export default class UtilsCombobox extends LightningElement {
	@api label;
	@api placeholder;

	@api
	get value() {
		return this._value;
	}
	set value(value) {
		this._value = value;
		if (value) {
			this.selectOption();
		}
	}

	@api
	get records() {
		if (this.data?.records) {
			return [...this.data?.records];
		} else {
			return [];
		}
	}
	set records(records) {
		if (records) {
			this.loadRecords(records);
		}
	}

	@api
	get fieldValue() {
		return this._fieldValue;
	}
	set fieldValue(value) {
		if (value) {
			this._fieldValue = value;
			if (this.isDataLoaded) {
				this.loadRecords(this.data.records);
			}
		}
	}

	@api
	get fieldLabel() {
		return this._fieldLabel;
	}
	set fieldLabel(value) {
		if (value) {
			this._fieldLabel = value;
			if (this.isDataLoaded) {
				this.loadRecords(this.data.records);
			}
		}
	}

	_value;
	_fieldValue = "Id";
	_fieldLabel = "Name";
	data = {
		// {label, value, selected}
		records: [], // [record]
		mapOptions: new Map(), // value => option
		listOptions: [], // [option]
	};

	get isDataLoaded() {
		return this.data?.records?.length > 0;
	}

	get isPlaceHolderSelected() {
		return !this.value;
	}

	onOptionChange(event) {
		this.value = event.detail.value;
	}

	loadRecords(records) {
		const tempData = {
			records: [], // [record]
			mapOptions: new Map(), // value => option
			listOptions: [], // [{label, value, selected}]
		};
		records?.forEach((record) => {
			const option = {
				selected: false,
				label: record[this.fieldLabel],
				value: record[this.fieldValue],
				record,
			};
			tempData.records.push(record);
			tempData.mapOptions.set(option.value, option);
			tempData.listOptions.push(option);
		});
		this.data = tempData;
		if (this.value) {
			this.selectOption();
		}
	}

	selectOption() {
		if (this.isDataLoaded) {
			this.data.listOptions.forEach((option) => (option.selected = false));
			if (this.data.mapOptions.has(this.value)) {
				const option = this.data.mapOptions.get(this.value);
				option.selected = true;
				this.dispatchEvent(new CustomEvent("change", { detail: { ...option } }));
			}
		}
	}
}
